<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>
    <section class="structure-main">
      <div class="white-container">
        <h5>{{ detail.title }}</h5>
        <div class="ckEditor-html" v-html="detail.text">
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import BreadCrumb from '@/components/BreadCrumb'

export default {
  name: 'StructureChamberDetail',
  data () {
    return {
      detail: {},
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Структура ОП РФ',
          path: '/structure_chamber'
        },
        {
          title: ''
        }
      ]
    }
  },
  mounted () {
    this.setDetail()
  },
  methods: {
    async setDetail () {
      let res = await fetch(`${this.$store.getters.getUrlApi}api/admin/chamber-register/${this.$route.params.id}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.detail = await res.data
      this.navigationLink[2].title = await res.data.title
    }
  },
  components: {
    BreadCrumb
  }
}
</script>
<style lang="scss" scoped>
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
    p{
      font-size: .88rem;
      line-height: 1.38rem;
      color: rgba(23, 23, 23, 0.8);
    }
  }
}
.structure-main{
  display: flex;
  justify-content: space-between;
  width: 90rem;
  margin: 0 auto;
}
.white-container{
  display: flex;
  flex-direction: column;
}
h5{
  font-weight: 600;
  font-size: 1.38rem;
  line-height: 1.75rem;
  color: #1F3245;
  margin-bottom: 2rem;
}
@media screen and (max-width: 768px){
  .structure-main{
    width: auto;
  }
}
</style>
